/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

function UserProfile() {
  return (
    <div className="kt-notification">
      <div className="kt-notification__custom">
        <Link to="/logout" className="btn btn-label-brand btn-sm btn-bold">
          Sign Out
        </Link>
      </div>
    </div>
  );
}

export default UserProfile;
