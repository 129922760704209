import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import NavigateBackIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import Table from "react-bootstrap/Table";
import { useHistory } from "react-router-dom";
import "./styles.css";

export default function RentPage() {
  let history = useHistory();

  const classes = useStyles();

  const chosePayimentState = "Chose payiment state";

  const choseReservationState = "Chose reservation state";

  const allPayments = "All payments";
  const waitingPayments = "Pending";
  const completedPayments = "Completed";
  const incompletePayments = "Incompleted";

  const allReservations = "All reservations";
  const pendingReservations = "Pending";
  const ongoingReservations = "Ongoing";
  const completeReservations = "Complete";
  const incompleteReservations = "Incomplete";
  const canceledReservations = "Canceled";
  const waitingForPayment = "Waiting for payment";

  const selectState = "Select state";

  const cancel = "Cancel";

  const confirm = "Confirm";

  const stateText = "State";

  const nameAndIdText = "Name/Id Scooter";

  const paymentText = "Payment";

  const reservationText = "Reservation";

  const [clients, setClients] = useState([]);

  const [search, setSearch] = useState("");
  const [paginationEnable, setPaginationEnable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [tabelPage, setTabelPage] = useState(10);
  const [tabelTotalItems, setTabelTotalItems] = useState(0);

  // onclick pagination effect
  const [hoveredItemLeft, setHoveredItemLeft] = useState(false);
  const [hoveredItemRight, setHoveredItemRight] = useState(false);

  const [open, setOpen] = useState(false);

  const [state, setState] = useState("");

  const [stateFilter, setStateFilter] = useState(10);

  const [paymentFilter, setPaymentFilter] = useState(4);

  const [selectedReservationId, setSelectedReservationId] = useState("");

  const [sediu, setSediu] = useState("");

  const reservationsObj = [
    { text: "Completed", id: "1" },
    { text: "Ongoing", id: "3" },
    { text: "Canceled", id: "5" },
  ];

  const clientsSort = clients ? clients : [];

  // paginationButtonStyle
  let paginationLeftButtonStyle;
  if (hoveredItemLeft) {
    paginationLeftButtonStyle = {
      marginLeft: "5px",
      background: "rgb(224, 224, 224)",
      borderRadius: "10px",
    };
  } else {
    paginationLeftButtonStyle = { marginLeft: "5px", background: "none" };
  }

  let paginationRightButtonStyle;
  if (hoveredItemRight) {
    paginationRightButtonStyle = {
      background: "rgb(224, 224, 224)",
      borderRadius: "10px",
    };
  } else {
    paginationRightButtonStyle = { background: "none" };
  }

  async function getUsersData() {
    if (sediu) {
      setPaginationEnable(true);
      setLoading(true);
      const dataToSend = {
        page: tabelPage,
        items_per_page: itemsPerPage,
        column_name: "start",
        order: "DESC",
        sediu: sediu,
        filter: stateFilter,
        plata_filter: paymentFilter,
      };
      fetch("/admin/selectRezervari.php", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
        },
        body: JSON.stringify(dataToSend),
      })
        .then((response) => response.json())
        .then((data) => {
          const clientsData = data.slice(0, data.length - 1);
          const totalItems = data.slice(data.length - 1)[0].row_nr;
          setLoading(false);
          setClients(clientsData);
          setTabelTotalItems(parseInt(totalItems));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  async function getSearchedUsers() {
    setPaginationEnable(false);
    setLoading(true);
    fetch("/admin/searchRezervare.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
      },
      body: JSON.stringify({
        querry: search,
        sediu: sediu,
        filter: stateFilter,
        plata_filter: paymentFilter,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setClients(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function updateReservationStatus(e) {
    e.preventDefault();
    // https://rent.e-twow.app/admin/updateRezervare.php
    // $id_rezervare=$json['id_rezervare'];
    // $status=$json['status'];
    setLoading(true);

    fetch("/admin/updateRezervare.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
      },
      body: JSON.stringify({
        id_rezervare: selectedReservationId,
        status: state,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setOpen(false);
        getUsersData();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleStateFilter = (event) => {
    setStateFilter(event.target.value);
  };

  const handlePaymentFilter = (event) => {
    setPaymentFilter(event.target.value);
  };

  useEffect(() => {
    // getClients();
    setLoading(true);

    // var aValue = JSON.parse(localStorage.getItem("persist:demo1-auth"))
    //   .authToken[1];
    const idSediu = localStorage.getItem("sediuEasyRental");

    if (idSediu) return setSediu(idSediu);
    else return history.push("/logout");
  }, [history]);

  useEffect(() => {
    if (sediu) getUsersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabelPage, itemsPerPage, sediu]);

  useEffect(() => {
    setTabelPage(1);
    getUsersData();
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateFilter, paymentFilter]);

  useEffect(() => {
    if (search.length >= 3) {
      getSearchedUsers();
    } else if (search.length === 0) {
      setTabelPage(1);
      setItemsPerPage(10);
      getUsersData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        className="kt-portlet"
        style={{ minHeight: "100%" }}
      >
        <form
          noValidate
          autoComplete="off"
          className="kt-portlet__body"
          style={{ minWidth: "95%" }}
        >
          {/* <AddParts /> */}
          <div className="bodyTable">
            <div
              className={classes.margin}
              style={{
                margin: "20px 25px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",
                minHeight: 50,
              }}
            >
              <TextField
                style={{ marginBottom: 20 }}
                label="Search Clients"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                value={search}
              />
              <div>
                {!loading ? (
                  <FormControl
                    className={classes.formControl}
                    style={{ marginTop: 0, marginRight: 20, marginBottom: 20 }}
                  >
                    <InputLabel id="demo-simple-select-helper-label">
                      {chosePayimentState}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={paymentFilter}
                      onChange={handlePaymentFilter}
                    >
                      <MenuItem value={4}>{allPayments}</MenuItem>
                      <MenuItem value={0}>{waitingPayments}</MenuItem>
                      <MenuItem value={1}>{completedPayments}</MenuItem>
                      <MenuItem value={2}>{incompletePayments}</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <CircularProgress style={{ margin: "10px 10px 0 0" }} />
                )}
                {!loading ? (
                  <FormControl
                    className={classes.formControl}
                    style={{ marginTop: 0, marginBottom: 10 }}
                  >
                    <InputLabel id="demo-simple-select-helper-label">
                      {choseReservationState}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={stateFilter}
                      onChange={handleStateFilter}
                    >
                      <MenuItem value={10}>{allReservations}</MenuItem>

                      <MenuItem value={0}>{pendingReservations}</MenuItem>
                      <MenuItem value={3}>{ongoingReservations}</MenuItem>
                      <MenuItem value={1}>{completeReservations}</MenuItem>
                      <MenuItem value={2}>{incompleteReservations}</MenuItem>
                    </Select>
                  </FormControl>
                ) : null}
              </div>
            </div>
            <Table>
              <thead>
                <tr>
                  <th style={tableItems}>Email</th>
                  <th style={tableItems}>Start</th>
                  <th style={tableItems}>Stop</th>
                  <th style={tableItems}>{nameAndIdText}</th>
                  <th style={tableItems}>{paymentText}</th>

                  <th style={tableItems}>{reservationText}</th>

                  <th style={tableItems}>Action</th>

                  {/* <th style={tableItems}>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {clientsSort.length === 0 ? (
                  <tr>
                    <td style={{ ...tableItems, display: "hidden" }}></td>
                    <td style={{ ...tableItems, display: "hidden" }}></td>

                    <td style={tableItems}>
                      <p style={{ textAlign: "center", margin: "30px" }}>
                        Niciun rezultat
                      </p>
                    </td>
                    <td style={{ ...tableItems, display: "hidden" }}></td>

                    <td style={{ ...tableItems, display: "hidden" }}></td>

                    <td style={{ ...tableItems, display: "hidden" }}></td>
                    <td style={{ ...tableItems, display: "hidden" }}></td>
                  </tr>
                ) : (
                  clientsSort.map((reservation, rowIndex) => (
                    <tr
                      key={rowIndex}
                      style={{
                        height: "50px",
                        lineHeight: "30px",
                      }}
                    >
                      <td style={tableItems}>{reservation.username}</td>
                      <td style={tableItems}>
                        {reservation.start.substr(8, 2)}-
                        {reservation.start.substr(5, 2)}-
                        {reservation.start.substr(0, 4)}
                      </td>
                      <td style={tableItems}>
                        {reservation.end.substr(8, 2)}-
                        {reservation.end.substr(5, 2)}-
                        {reservation.end.substr(0, 4)}
                      </td>
                      <td style={tableItems}>
                        {reservation.nume} / {reservation.id_trotineta}
                      </td>
                      {reservation.status === "1" ? (
                        <td style={{ ...tableItems, color: "green" }}>
                          {completedPayments}
                        </td>
                      ) : reservation.status === "2" ? (
                        <td style={{ ...tableItems, color: "red" }}>
                          {incompletePayments}
                        </td>
                      ) : (
                        <td style={tableItems}>{waitingPayments}</td>
                      )}
                      {reservation.rezervare && reservation.status === "0" ? (
                        <td style={{ ...tableItems }}>{waitingForPayment}</td>
                      ) : reservation.rezervare === "1" ? (
                        <td style={{ ...tableItems, color: "green" }}>
                          {completeReservations}
                        </td>
                      ) : reservation.rezervare === "2" ? (
                        <td style={{ ...tableItems, color: "red" }}>
                          {incompleteReservations}
                        </td>
                      ) : reservation.rezervare === "0" ? (
                        <td style={{ ...tableItems, color: "black" }}>
                          {pendingReservations}
                        </td>
                      ) : reservation.rezervare === "3" ? (
                        <td style={{ ...tableItems, color: "#FF00FF" }}>
                          {ongoingReservations}
                        </td>
                      ) : (
                        <td style={{ ...tableItems, color: "orange" }}>
                          {canceledReservations}
                        </td>
                      )}
                      <td style={tableItems}>
                        {reservation.rezervare !== "2" &&
                        reservation.rezervare !== "5" &&
                        reservation.rezervare !== "9" &&
                        reservation.rezervare !== "4" &&
                        reservation.rezervare !== "1" ? (
                          <Button
                            onClick={() => {
                              setOpen(true);
                              setSelectedReservationId(
                                reservation.id_rezervare
                              );
                            }}
                            style={elementsContainers}
                            variant="contained"
                            color="primary"
                            className={classes.button}
                          >
                            {stateText}
                          </Button>
                        ) : null}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
              {paginationEnable ? (
                <tfoot>
                  <tr>
                    <td>
                      {loading && (
                        <CircularProgress style={{ margin: "10px 0 0 30px" }} />
                      )}
                    </td>
                    <td align="right">
                      <div style={tableFooter}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "80%",
                          }}
                        >
                          <p
                            style={{
                              paddingTop: "15px",
                              fontSize: "12px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Rows :
                          </p>
                          <FormControl className={classes.margin}>
                            <Select
                              value={itemsPerPage}
                              onChange={(e) => setItemsPerPage(e.target.value)}
                              input={
                                <BootstrapInput
                                  name="TablePage"
                                  id="tabelPage-customized-select"
                                />
                              }
                            >
                              <MenuItem value={10}>10</MenuItem>
                              <MenuItem value={15}>15</MenuItem>
                              <MenuItem value={25}>25</MenuItem>
                              <MenuItem value={50}>50</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div style={paginationButtons}>
                          <p style={{ whiteSpace: "nowrap", width: "75px" }}>
                            Page: {tabelPage < 10 ? "0" + tabelPage : tabelPage}
                            /{parseInt(tabelTotalItems / itemsPerPage + 1)}{" "}
                          </p>
                          {tabelPage !== 1 ? (
                            <NavigateBackIcon
                              style={paginationLeftButtonStyle}
                              onMouseDown={() => setHoveredItemLeft(true)}
                              onMouseUp={() => setHoveredItemLeft(false)}
                              onClick={(e) => {
                                e.preventDefault();
                                if (tabelPage > 1) setTabelPage(tabelPage - 1);
                              }}
                            />
                          ) : (
                            <div style={{ width: 19.5, marginLeft: 5 }}> </div>
                          )}

                          {tabelPage !==
                          parseInt(tabelTotalItems / itemsPerPage + 1) ? (
                            <NavigateNextIcon
                              style={paginationRightButtonStyle}
                              onMouseDown={() => setHoveredItemRight(true)}
                              onMouseUp={() => setHoveredItemRight(false)}
                              onClick={(e) => {
                                e.preventDefault();
                                if (
                                  tabelPage <
                                  parseInt(tabelTotalItems / itemsPerPage + 1)
                                )
                                  setTabelPage(tabelPage + 1);
                              }}
                            />
                          ) : (
                            <div style={{ width: 19.5, marginLeft: 5 }}> </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              ) : null}
            </Table>
          </div>
        </form>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{selectState}</DialogTitle>
        <DialogContent
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {reservationsObj.map((reservation) => (
            <DialogContentText
              id="alert-dialog-description"
              key={reservation.id}
              onClick={() => setState(reservation.id)}
            >
              <Button
                style={{
                  color: reservation.id === state ? "white" : "black",
                  backgroundColor:
                    reservation.id === state
                      ? "rgb(92,120,255)"
                      : "transparent",
                  borderRadius: 10,
                  width: "100%",
                }}
              >
                {reservation.text}
              </Button>
            </DialogContentText>
          ))}
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button onClick={() => setOpen(false)} color="primary">
            {cancel}
          </Button>
          <Button onClick={updateReservationStatus} color="primary" autoFocus>
            {confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 160,
  },
}));

const tableItems = {
  borderBottom: "1px solid rgb(224, 224, 224)",
  borderBottomWidth: "1px",
  borderBottomStyle: "solid",
  borderBottomColor: " rgb(224, 224, 224)",
  width: "(100/6)%",
  alignSelf: "center",
};

const tableFooter = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  heigth: "50px",
  paddingBottom: "10px",
  paddingTop: "10px",
  paddingRight: 0,
  paddingLeft: 0,
  marginLeft: "-20px",
};

const paginationButtons = {
  display: "flex",
  flexDirection: "row",
  verticalAlign: "baseline",
  paddingTop: "15px",
  width: "120px",
  marginLeft: "10px",
  alignContent: "flex-end",
};

const elementsContainers = {
  margin: 0,
};
