import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

export default function ReportPage() {
  const classes = useStyles();

  const generateReport = "Generate report";

  const startDateText = "Start date";

  const endDateText = "End date";

  const locationText = "Location";

  const initialDate = `${new Date().getDate()}-${new Date().getMonth() +
    1}-${new Date().getFullYear()}`;

  const [selectedStartDate, setSelectedStartDate] = useState(initialDate);
  const [selectedEndDate, setSelectedEndDate] = useState(initialDate);

  const [selectedLocation, setSelectedLocation] = useState("1");

  const sediiDisponibile = [
    { nume: "Cluj", id: "1" },
    { nume: "Bucuresti", id: "2" },
    { nume: "Craiova", id: "3" },
    { nume: "Hotel Marie Constanta", id: "4" },
    { nume: "Piata Ovidiu Constanta", id: "5" },
    { nume: "Italia", id: "5" },
    { nume: "Malta", id: "6" },
  ];

  function handleStartDateChange(date) {
    const formatDate = `${new Date(date).getDate()}-${new Date(
      date
    ).getMonth() + 1}-${new Date(date).getFullYear()}`;

    setSelectedStartDate(formatDate);
  }

  function handleEndDateChange(date) {
    const formatDate = `${new Date(date).getDate()}-${new Date(
      date
    ).getMonth() + 1}-${new Date(date).getFullYear()}`;

    setSelectedEndDate(formatDate);
  }

  function handleLocationChange(location) {
    setSelectedLocation(location.target.value);
  }

  useEffect(() => {
    const idSediu = localStorage.getItem("sediuEasyRental");

    setSelectedLocation(idSediu);
  }, []);
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      className="kt-portlet"
      style={{ height: "50%", padding: 50 }}
    >
      <form
        noValidate
        autoComplete="off"
        className="kt-portlet__body"
        style={{
          width: "50%",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container className={classes.grid} justify="space-around">
            <KeyboardDatePicker
              margin="normal"
              id="mui-pickers-date"
              label={startDateText}
              value={selectedStartDate}
              format="dd/MM/yyyy"
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              style={{ marginLeft: "auto", marginRight: 0 }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        {/* 2 */}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container className={classes.grid} justify="space-around">
            <KeyboardDatePicker
              margin="normal"
              id="mui-pickers-date"
              label={endDateText}
              value={selectedEndDate}
              format="dd/MM/yyyy"
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              style={{ marginLeft: "auto", marginRight: 0 }}
            />
          </Grid>
        </MuiPickersUtilsProvider>

        <FormControl
          className={classes.formControl}
          style={{ marginTop: 10, marginBottom: 20 }}
        >
          <InputLabel htmlFor="age-simple">{locationText}</InputLabel>
          <Select
            disabled={true}
            value={selectedLocation}
            onChange={handleLocationChange}
            inputProps={{
              nume: "age",
              id: "age-simple",
            }}
          >
            {sediiDisponibile.map((sediu) => (
              <MenuItem value={sediu.id} key={sediu.id}>
                {sediu.nume}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <a
          href={`http://rent.e-twow.app/genereaza_pdf.php?start=${selectedStartDate}&stop=${selectedEndDate}`}
          download="Da"
          style={{ width: "100%" }}
        >
          <Button
            style={{ right: 0, width: "100%" }}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            {generateReport}
            <ArrowForwardIcon
              className={clsx(classes.rigthIcon, classes.iconSmall)}
              style={{ marginLeft: 5 }}
            />
          </Button>
        </a>
      </form>
    </Grid>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
  },
}));
