import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// import { Link } from "react-router-dom";

export default function AddRent() {
  const classes = useStyles();

  const [numeClient, setNumeClient] = useState("");

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [days, setDays] = useState(1);

  const sediiDisponibile = [
    "Cluj",
    "Bucuresti",
    "Craiova",
    "Hotel Marie Constanta",
    "Piata Ovidiu Constanta",
    "Italia",
    "Malta",
    "Germany",
  ];

  // [
  //   { nume: "Cluj", id: 1 },
  //   { nume: "Bucuresti", id: 2 },
  //   { nume: "Craiova", id: 3 },
  // ]

  const [sediuAdmin, setSediuAdmin] = useState("");

  const [sediuAdminText, setSediuAdminText] = useState("");

  const [selectedScooter, setSelectedScooter] = useState("");

  const placeholders = [
    "Email Client",
    "Choose the date",
    "Choose the reservation duration",
    "Location",
    "Add reservation",
    "See available scooters",
    "Alege o trotineta disponibila",
  ];

  const invalidEmailFormat = "Invalid email format";

  const sucfullyReservation = "Reservation added successfully";

  const [scootersAvaible, setScootersAvaible] = useState([]);

  const [open, setOpen] = useState(false);

  const [openMessage, setOpenMessage] = useState(false);

  const [messageText, setMessageText] = useState("");

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleCloseMessage() {
    setOpenMessage(false);
    setMessageText("");
  }

  function handleDateChange(date) {
    setSelectedDate(date);
  }

  const handleChangeDays = (event) => {
    setDays(event.target.value);
  };

  async function postFisa() {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/;

    const reservationData = {
      sediu: sediuAdmin,
      data_start: selectedDate,
      nr_zile: days,
    };
    if (numeClient.match(mailformat)) {
      fetch("/cauta_trotinete.php", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
        },
        body: JSON.stringify(reservationData),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data[1]);
          setScootersAvaible(data[1]);
          return handleClickOpen();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setMessageText(invalidEmailFormat);
      setOpenMessage(true);
    }
  }

  async function addRezervare() {
    const reservationData = {
      start: selectedDate,
      end: days,
      username: numeClient,
      id_troti: selectedScooter,
    };
    if (numeClient !== "")
      fetch("/admin/addRezervare.php", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
        },
        body: JSON.stringify(reservationData),
      })
        .then((response) => response.json())
        .then((data) => {
          setNumeClient("");
          setSelectedDate(new Date());
          setOpen(false);
          setMessageText(sucfullyReservation);
          setOpenMessage(true);
        })
        .catch((error) => {
          console.error(error);
          setMessageText("Error");
          setOpenMessage(true);
        });
  }

  async function getSedii() {
    fetch(`/sediu_test.php?locatie=${1}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Done");
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    const idSediu = localStorage.getItem("sediuEasyRental");
    getSedii();

    setSediuAdmin(idSediu);
    setSediuAdminText(sediiDisponibile[idSediu - 1]);
    // eslint-disable-next-line
  }, [sediiDisponibile]);

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        className="kt-portlet"
      >
        <form noValidate autoComplete="off" className="kt-portlet__body">
          <div style={sectionStyle}>
            <h1 className="kt-portlet__head">{placeholders[4]}</h1>
            <div>
              <TextField
                className={clsx(classes.margin, classes.textField)}
                //   variant="default"
                margin="normal"
                label={placeholders[0]}
                value={numeClient}
                onChange={(e) => setNumeClient(e.target.value)}
                error={numeClient === ""}
                helperText={numeClient === "" ? "Empty field!" : " "}
                style={elementsContainers}
              />
              <br />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container className={classes.grid} justify="space-around">
                  <KeyboardDatePicker
                    margin="normal"
                    id="mui-pickers-date"
                    label={placeholders[1]}
                    value={selectedDate}
                    format="dd/MM/yyyy"
                    onChange={handleDateChange}
                    style={elementsContainers}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              <FormControl
                className={classes.formControl}
                margin="normal"
                style={elementsContainers}
              >
                <InputLabel id="reservation-time">{placeholders[2]}</InputLabel>
                <Select
                  labelId="reservation-time"
                  value={days}
                  onChange={handleChangeDays}
                >
                  {/* days */}
                  <MenuItem value={1}>1 day</MenuItem>
                  <MenuItem value={2}>2 days</MenuItem>
                  <MenuItem value={3}>3 days</MenuItem>
                  <MenuItem value={4}>4 days</MenuItem>
                  <MenuItem value={5}>5 days</MenuItem>
                  <MenuItem value={6}>6 days</MenuItem>
                  <MenuItem value={7}>7 days</MenuItem>
                  {/* hours */}
                  <MenuItem value={98}>1 hour</MenuItem>
                  <MenuItem value={99}>3 hours</MenuItem>
                </Select>
                {/* <FormHelperText>Some important helper text</FormHelperText> */}
              </FormControl>
              <TextField
                className={clsx(classes.margin, classes.textField)}
                //   variant="default"
                margin="normal"
                label={placeholders[3]}
                value={sediuAdminText}
                style={elementsContainers}
                disabled={true}
              />
            </div>

            <div style={buttonsContainer}>
              <Button
                onClick={postFisa}
                style={{ ...elementsContainers, width: "100%" }}
                variant="contained"
                color="primary"
                className={classes.button}
              >
                {placeholders[5]}
                <ArrowForwardIcon
                  className={clsx(classes.rigthIcon, classes.iconSmall)}
                  style={{ marginLeft: 5 }}
                />
              </Button>
            </div>
          </div>
        </form>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{placeholders[6]}</DialogTitle>
        <DialogContent
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {scootersAvaible.map((scooter) => (
            <DialogContentText
              id="alert-dialog-description"
              key={scooter.id}
              onClick={() => setSelectedScooter(scooter.id)}
            >
              <Button
                style={{
                  color: selectedScooter === scooter.id ? "white" : "black",
                  backgroundColor:
                    selectedScooter === scooter.id
                      ? "rgb(92,120,255)"
                      : "transparent",
                  borderRadius: 10,
                  width: "100%",
                }}
              >
                {scooter.nume} - {scooter.cod}
              </Button>
            </DialogContentText>
          ))}
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button onClick={handleClose} color="primary">
            Anuleaza
          </Button>
          <Button onClick={addRezervare} color="primary" autoFocus>
            Confirma
          </Button>
        </DialogActions>
      </Dialog>
      {/* confirmare/eroare */}
      <Dialog
        open={openMessage}
        onClose={handleCloseMessage}
        aria-labelledby="alert-dialog-title-message"
        aria-describedby="alert-dialog-description-message"
      >
        {/* <DialogTitle id="alert-dialog-title">{placeholders[6]}</DialogTitle> */}
        <DialogContent
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {messageText}
        </DialogContent>
        <DialogActions style={{ right: 0 }}>
          <Button onClick={handleCloseMessage} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const buttonsContainer = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: 20,
};

const sectionStyle = {
  marginTop: 20,
};

const elementsContainers = {
  margin: 0,
  marginTop: 50,
};
