export default {
  header: {
    self: {},
    items: [
      {
        title: "Dashboards",
        root: true,
        alignment: "left",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
      },
      {
        title: "Utils",
        root: true,
        alignment: "left",
        toggle: "click",
        submenu: {
          type: "mega",
          width: "800px",
          alignment: "left",
          columns: [
            {
              items: [
                {
                  title: "Add Reservation",
                  page: "addrent",
                },
                {
                  title: "Scooter map",
                  page: "maps",
                },
                {
                  title: "Generate report",
                  page: "report",
                },
              ],
            },
          ],
        },
      },
    ],
  },
  // stanga
  aside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        bullet: "dot",
      },
      { section: "Utils" },
      {
        title: "Add Reservation",
        root: true,
        bullet: "dot",
        icon: "flaticon2-add",
        page: "addrent",
        // submenu: [
        //   {
        //     title: "AddDefects",
        //     page: "service/defects"
        //   },
        //   {
        //     title: "AddParts",
        //     page: "service/parts"
        //   }
        // ]
      },
      {
        title: "Scooter map",
        root: true,
        bullet: "dot",
        icon: "flaticon-map-location",
        page: "maps",
      },
      {
        title: "Generate report",
        root: true,
        bullet: "dot",
        icon: "flaticon-psd",
        page: "report",
      },
    ],
  },
};
