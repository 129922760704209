import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "./styles.css";

export default function Maps() {
  const classes = useStyles();

  const [markers, setMarkers] = useState([]);

  const [center, setCenter] = useState({ lat: 44.319305, lng: 23.800678 });

  const zoom = 11;

  let [filterScooterSearch, setFilterScooterSearch] = useState("");

  const [markerInfoShow, setMarkerInfoShow] = useState("");

  const searchLabel = "Search scooter";

  const searchScooter = "Cauta trotinete";

  const nameText = "Name";

  useEffect(() => {
    getScooters();
  }, []);

  async function getScooters() {
    //   const reservationData = {
    //     sediu: selectedValueId,
    //     data_start: selectedDate,
    //     nr_zile: days
    //   };
    fetch("/json.php?auth=R4g6@iPn[,TyUN&location=romania", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setMarkers(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const filteredMarkers = markers.filter(
    (marker) =>
      marker.scooter_id
        .toLowerCase()
        .indexOf(filterScooterSearch.toLowerCase()) !== -1 ||
      marker.info.toLowerCase().indexOf(filterScooterSearch.toLowerCase()) !==
        -1
  );

  function onTrotListClick(marker) {
    setCenter({ lat: marker.lat, lng: marker.lng });
    setMarkerInfoShow(marker.scooter_id);
  }

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };

  // uhu

  return (
    <div
      style={{
        display: "flex",
        height: "90vh",
        width: "100%",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          width: "30%",
          background: "white",
          padding: 20,
          textAlign: "center",
          height: "100%",
          overflow: "scroll",
        }}
      >
        <h1>{searchScooter}</h1>
        <TextField
          id="standard-name"
          label={searchLabel}
          className={classes.textField}
          value={filterScooterSearch}
          onChange={(e) => setFilterScooterSearch(e.target.value)}
          margin="normal"
        />
        <div style={{ height: "90vh" }}>
          {filteredMarkers.map((marker, index) => (
            <div
              key={marker.scooter_id}
              onClick={() => onTrotListClick(marker)}
              style={{
                backgroundColor: "#e1e1ef",
                cursor: "pointer",
                borderRadius: 10,
                padding: 10,
                margin: 10,
              }}
            >
              {/* <p style={{ marginBottom: 5 }}>Model: {marker.info} </p> */}
              <p style={{ margin: 0 }}>
                {nameText}: {marker.scooter_id}
              </p>
            </div>
          ))}
        </div>
      </div>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyD8ks48g96OXYUOrxYN5aapwTyu5QzWJ30" }}
        center={center}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        {markers.map((marker, index) => (
          <Marker
            key={index}
            lat={marker.lat}
            lng={marker.lng}
            name={`${marker.scooter_id}`}
            id={index}
            color={"blue"}
            onTrotListClick={onTrotListClick}
            markerInfoShow={markerInfoShow}
            setMarkerInfoShow={setMarkerInfoShow}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
}

const Marker = (props) => {
  const {
    color,
    name,
    markerInfoShow,
    lat,
    lng,
    onTrotListClick,
    setMarkerInfoShow,
  } = props;
  const marker = { scooter_id: name, lat, lng };

  return (
    <div
      // className="marker"
      style={{ backgroundColor: color, cursor: "pointer" }}
      title={name}
    >
      {markerInfoShow === name && (
        <div style={markerInfoContainer} onClick={() => setMarkerInfoShow("")}>
          {/* <p
            style={{ position: "absolute", top: 0, right: 0, padding: 5 }}
            onClick={() => setMarkerInfoShow("")}
          >
            X
          </p> */}
          <p style={{ margin: 0, fontWeight: "bold", zIndex: 1000 }}>{name}</p>
        </div>
      )}

      <img
        src={require("./marker.png")}
        style={{
          height: 50,
          width: 40,
          marginTop: -50,
          marginLeft: -12,
          position: "absolute",
          zIndex: markerInfoShow === name ? 1000 : -1,
        }}
        onClick={() => onTrotListClick(marker)}
        alt={name}
      />
    </div>
    // markerInfoShow
  );
};

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const markerInfoContainer = {
  position: "absolute",
  marginTop: -110,
  marginLeft: -20,
  backgroundColor: "white",
  paddingTop: 20,
  paddingBottom: 20,
  paddingRight: 10,
  paddingLeft: 10,
  borderRadius: 10,
};
