import React, { useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });

  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");

  const [errorText, setErrorText] = useState("");

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  return (
    <>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
          </div>
          {/* admin@demo.com */}
          {/* demo */}
          <Formik
            initialValues={{
              email: "admin@demo.com",
              password: "demo",
            }}
            validate={(values) => {
              const errors = {};

              if (!values.email) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              }

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              }

              return errors;
            }}
            onSubmit={() => {
              enableLoading();
              const reservationData = {
                email: emailValue,
                password: passwordValue,
              };
              if (emailValue !== "" && passwordValue !== "") {
                fetch("/admin/login.php", {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Cache-Control": "no-cache, no-store",
                  },
                  body: JSON.stringify(reservationData),
                })
                  .then((response) => response.json())
                  .then((data) => {
                    disableLoading();

                    if (data.valid === "2") {
                      setErrorText("Username sau parola incorecte");
                      setTimeout(() => {
                        setErrorText("");
                      }, 1700);
                    } else {
                      props.login(data.id_sediu);
                      localStorage.setItem("sediuEasyRental", data.id_sediu);
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                    disableLoading();
                  });
              } else {
                setErrorText("Completeaza campurile");
                disableLoading();
              }
            }}
          >
            {({ handleBlur, handleSubmit, isSubmitting }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {errorText ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{errorText}</div>
                  </div>
                ) : // <div role="alert" className="alert alert-info">
                //   <div className="alert-text">
                //     Use account <strong>admin@demo.com</strong> and password{" "}
                //     <strong>demo</strong> to continue.
                //   </div>
                // </div>
                null}

                <div className="form-group">
                  <TextField
                    type="text"
                    label="Username"
                    margin="normal"
                    className="kt-width-full"
                    name="email"
                    onBlur={handleBlur}
                    onChange={(e) => setEmailValue(e.target.value)}
                    value={emailValue}
                    helperText={emailValue === "" ? "Completeaza campul" : null}
                    error={Boolean(emailValue === "")}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Password"
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={(e) => setPasswordValue(e.target.value)}
                    value={passwordValue}
                    helperText={
                      passwordValue === "" ? "Completeaza campul" : null
                    }
                    error={Boolean(passwordValue === "")}
                  />
                </div>

                <div className="kt-login__actions">
                  <div></div>
                  <button
                    onClick={onsubmit}
                    id="kt_login_signin_submit"
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
                    Sign In
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
