import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import AddRent from "./userAcces/AddRent";
import RentPage from "./userAcces/RentPage";
import Maps from "./userAcces/Maps";
import ReportPage from "./userAcces/ReportPage";

export default function HomePage() {
  // useEffect(() => {
  //   console.log('Home page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        {/* <Route path="/dashboard" component={MainPage} /> */}

        <Route path="/dashboard" component={RentPage} />
        <Route path="/addrent" component={AddRent} />
        <Route path="/maps" component={Maps} />
        <Route path="/report" component={ReportPage} />

        {/* <Route path="/service/parts" component={PartsPage} /> */}
        {/* <Route path="/service" component={Service} /> */}
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
